<template>
  <ui-component-modal
    :modalTitle="$t('Components.Vouchers.ModalEditText.Main_Title')"
    modalSize="large"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveVoucherText"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div v-if="mVoucher.Descriptions.length > 0" class="columns is-multiline">
        <div
          v-for="(textLanguage, index) in mVoucher.Descriptions"
          :key="'column' + index"
          class="column is-half"
        >
          <p>{{ $t('LanguageLabels.Language' + textLanguage.LanguageId) }}</p>
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="text"
                :placeholder="'Voucher name'"
                v-model="textLanguage.Name"
              />
            </div>
          </div>
          <div class="field">
            <div class="control">
              <ui-text-editor
                :id="'editor' + index"
                v-model="textLanguage.Text"
              ></ui-text-editor>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import voucherProvider from '@/providers/voucher'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    channelId: {
      type: Number,
      required: true,
    },

    locationId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mVoucher: null,
    }
  },

  computed: {
    ...mapState('voucherStore', ['voucher']),
  },

  created() {
    this.mVoucher = JSON.parse(JSON.stringify(this.voucher))
    if (this.mVoucher.Descriptions.length === 0) {
      this.setupVoucherDescriptions()
    }
  },

  methods: {
    ...mapMutations('voucherStore', ['setVoucher']),

    setupVoucherDescriptions() {
      let meetingIdArray = []
      for (
        let index = 0;
        index < this.mVoucher.MeetingtypeIds.length;
        index++
      ) {
        meetingIdArray.push(this.mVoucher.MeetingtypeIds[index])
      }

      this.mVoucher.Descriptions.push({
        VoucherId: this.mVoucher.Id,
        LanguageId: 52,
        Name: this.mVoucher.Name,
        Text: '',
      }),
        this.mVoucher.Descriptions.push({
          VoucherId: this.mVoucher.Id,
          LanguageId: 65,
          Name: this.mVoucher.Name,
          Text: '',
        })
    },

    saveVoucherText() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true
        this.mVoucher.ChannelId = this.channelId
        this.mVoucher.LocationId = this.locationId

        voucherProvider.methods
          .saveVoucher(self.mVoucher)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true
              self.setVoucher(response.data)

              setTimeout(() => {
                self.$emit('voucherEdited', response.data)
                self.onClickCancel()
              }, 1500)
            }
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },

    save() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true
      }
    },
  },
}
</script>
